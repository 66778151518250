<script setup lang="ts">

</script>

<template>
  <v-container>
    <v-row class="d-flex ga-4">
      <span>Landing page</span>
      <v-btn to="/dashboard" color="primary">Dashboard öffnen</v-btn>
    </v-row>
  </v-container>
</template>

<style scoped>

</style>
